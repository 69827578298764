import {
  Box,
  Container,
  MenuItem,
  AppBar as MuiAppBar,
  Toolbar,
  Typography,
} from "@mui/material";

import logo from "../../long-logo.svg";

const logoStyle = {
  width: "140px",
  height: "25px",
  cursor: "pointer",
};

export function AppBar() {
  const scrollToSection = (sectionId: string) => {
    const sectionElement = document.getElementById(sectionId);
    const offset = 128;
    if (sectionElement) {
      const targetScroll = sectionElement.offsetTop - offset;
      sectionElement.scrollIntoView({ behavior: "smooth" });
      window.scrollTo({
        top: targetScroll,
        behavior: "smooth",
      });
    }
  };

  return (
    <MuiAppBar
      position="fixed"
      color="transparent"
      sx={{
        width: "100%",
        bgcolor: "white",
        backdropFilter: "blur(24px)",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <Container maxWidth="lg">
        <Toolbar
          variant="regular"
          sx={(theme) => ({
            //TODO Claudia: define theme
            display: "flex",
            // alignItems: "center",
            // justifyContent: "space-between",
            flexShrink: 0,
            // borderRadius: "999px",
            // bgcolor:
            //   theme.palette.mode === "light"
            //     ? "rgba(255, 255, 255, 0.4)"
            //     : "rgba(0, 0, 0, 0.4)",
            // backdropFilter: "blur(24px)",
            maxHeight: 40,
            // border: "1px solid",
            // borderColor: "divider",
            // boxShadow:
            //   theme.palette.mode === "light"
            //     ? `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`
            //     : "0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)",
          })}
        >
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              alignContent: "flex-start",
              ml: "-18px",
              px: 0,
            }}
          >
            <img src={logo} style={logoStyle} alt="logo of hoppenhagen" />
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              <MenuItem onClick={() => scrollToSection("home")} sx={{}}>
                <Typography variant="body2" color="text.primary">
                  Home
                </Typography>
              </MenuItem>
              <MenuItem
                onClick={() => scrollToSection("aboutus")}
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography variant="body2" color="text.primary">
                  About us
                </Typography>
              </MenuItem>
              <MenuItem
                onClick={() => scrollToSection("benefits")}
                sx={{ py: "6px", px: "12px" }}
              >
                <Typography variant="body2" color="text.primary">
                  Benefits
                </Typography>
              </MenuItem>
              <MenuItem
                onClick={() => scrollToSection("pricing")}
                sx={{ py: "6px", px: "12px" }}
              >
                <Typography variant="body2" color="text.primary">
                  Pricing
                </Typography>
              </MenuItem>
              {/* <MenuItem
                onClick={() => scrollToSection("faq")}
                sx={{ py: "6px", px: "12px" }}
              >
                <Typography variant="body2" color="text.primary">
                  FAQ
                </Typography>
              </MenuItem> */}
            </Box>
          </Box>
        </Toolbar>
      </Container>
    </MuiAppBar>
  );
}
