import { Box, Container, Typography, Grid, Button } from "@mui/material";

import Image from "../../CoverPic.png";

export function Hero() {
  return (
    <Container maxWidth="lg" id="home" sx={{ mt: 1, mb: 10 }}>
      <Grid
        container
        spacing={4}
        alignItems="center"
        justifyContent="center"
        sx={{
          textAlign: { xs: "center", md: "left" }, // Center-align for smaller screens
        }}
      >
        {/* Left Text Content */}
        <Grid item xs={12} md={6}>
          <Typography
            component="h1"
            variant="h3"
            sx={{
              fontWeight: "bold",
              mb: 3,
              color: "white",
            }}
          >
            Welcome to Hoppenhagen!
          </Typography>
          <Typography
            variant="body1"
            paragraph
            sx={{
              fontSize: "1.2rem",
              lineHeight: 1.8,
              mb: 2,
            }}
          >
            Where fun meets fitness! Kangoo Jumps is now in Copenhagen, Denmark!
            Intensive, fun, and fat-burning workouts designed to get you
            energized!
          </Typography>
          <Typography
            variant="body1"
            paragraph
            sx={{
              fontSize: "1.1rem",
            }}
          >
            Come and jump with us!
          </Typography>
          <Button
            variant="contained"
            size="large"
            sx={{
              mt: 3,
              px: 4,
              py: 1.5,
              fontWeight: "bold",
              color: "#5a2f9e",
              backgroundColor: "#a2ffef",
              "&:hover": { backgroundColor: "#b5ffef" },
            }}
            onClick={() => {
              const pricingSection = document.getElementById("pricing");
              if (pricingSection) {
                pricingSection.scrollIntoView({ behavior: "smooth" });
              }
            }}
          >
            Buy trial session
          </Button>
        </Grid>

        {/* Right Image Content */}
        <Grid item xs={12} md={6}>
          <Box
            component="img"
            src={Image} // Add actual image URL
            alt="Kangoo Jumps Fun"
            sx={{
              width: "100%",
              height: "auto",
              borderRadius: "16px", // Rounded corners
              boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.2)", // Subtle shadow
            }}
          />
        </Grid>
      </Grid>
    </Container>
  );
}
