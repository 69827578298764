// import { Helmet } from "react-helmet-async";
import {
  AppBar,
  Page,
  Footer,
  Benefits,
  Hero,
  WelcomeSection,
} from "../../components";
import Pricing from "../../components/Pricing/Pricing";
import { Box } from "@mui/material";

export function Home() {
  return (
    <Page>
      {/* <Helmet>
        <title>Home</title>
      </Helmet> */}
      <AppBar></AppBar>

      <Box
        sx={{
          py: { xs: 6, md: 10 },
          background: "linear-gradient(to right, #5a2f9e, #59fc3c)",
          color: "#fff",
          textAlign: "center",
        }}
      >
        <Hero />
        <WelcomeSection />
        <Benefits />
        <Pricing />
        <Footer />
      </Box>
    </Page>
  );
}
