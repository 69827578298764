import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Email, Phone } from "@mui/icons-material";

export function Footer() {
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 4, sm: 8 },
        py: { xs: 8, sm: 10 },
        textAlign: { sm: "center", md: "left" },
      }}
    >
      <Box
        sx={{
          display: "flex", // Set display to flex
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: "space-between",
          pt: { xs: 4, sm: 8 },
          width: "100%",
          borderTop: "1px solid",
          borderColor: "divider",
          alignItems: { xs: "center", sm: "end" },
        }}
      >
        <Typography variant="body2" sx={{ color: "white", mt: 1 }}>
          {"Copyright ©Hoppenhagen 2024"}
        </Typography>
        <Box
          display="flex"
          alignItems="center"
          sx={{
            gap: { xs: 0, sm: 2 },
            justifyContent: "right",
            color: "white",
            display: "flex", // Set display to flex
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          <Box display="flex" alignItems="center">
            <Email />
            <Typography variant="body2">hoppenhagen@gmail.com</Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <Phone />
            <Typography variant="body2">+45 55 20 30 73</Typography>
          </Box>
        </Box>
        <Stack
          direction="row"
          spacing={1}
          useFlexGap
          sx={{ justifyContent: "left", color: "white" }}
        >
          <IconButton
            color="inherit"
            size="small"
            href="https://www.facebook.com/hoppenhagen.fitclub"
            aria-label="Facebook"
            sx={{ alignSelf: "center" }}
          >
            <FacebookIcon />
          </IconButton>
          <IconButton
            color="inherit"
            size="small"
            href="https://www.instagram.com/hoppenhagen.fitclub/"
            aria-label="Instagram"
            sx={{ alignSelf: "center" }}
          >
            <InstagramIcon />
          </IconButton>
          <IconButton
            color="inherit"
            size="small"
            href="https://www.linkedin.com/company/hoppenhagen"
            aria-label="Linkedin"
            sx={{ alignSelf: "center" }}
          >
            <LinkedInIcon />
          </IconButton>
        </Stack>
      </Box>
    </Container>
  );
}
